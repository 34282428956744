import './intro.scss'

import React, { Component } from 'react'

class Intro extends Component {

  render() {
    return (
      <div className="intro active" style={{backgroundColor: '#e6faf0'}}>
        <div className="intro-placeholder"></div>
        <div className="intro-logo">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 87.4 85" xmlSpace="preserve" fill="#000000">
            <g>
              <rect x="43.8" y="-0.9" transform="matrix(9.161673e-03 -1 1 9.161673e-03 41.7114 46.0985)" width="0.6" height="5.8"/>
              <polygon points="58,1.6 57,7.5 58.8,8.1 62,2.8 61.1,2.5 58,7.6 58.9,1.7 57.2,1.1 54.2,6.3 55,0.3 54.1,0 54.1,0 
                53.3,6.2 55.1,6.8 	"/>
              <path d="M61,9.2L62.4,8l2.4,1.4l-0.4,1.8l0.9,0.5l1.4-6.3l-1.6-1l-4.8,4.2L61,9.2z M65.7,5.1l-0.7,3.5L63,7.5L65.7,5.1
                z"/>
              <path d="M66.8,12.9l1.6-1.9l0.7,0.6c0,0,0.1,0.1,0.1,0.1l-0.6,2.7l0.8,0.7l0.6-2.9c0.8,0.3,1.5,0.2,2.1-0.5
                c0.7-0.9,0.4-2-0.7-3l-1.5-1.2L66,12.3L66.8,12.9z M70.1,8.9l0.8,0.6c0.7,0.5,0.8,1.1,0.4,1.6c-0.4,0.5-1,0.5-1.6-0.1l-0.8-0.7
                L70.1,8.9z"/>
              <path d="M73.8,16.1c0.4,0.7,0.4,1.2,0.1,1.5c-0.4,0.4-1.1,0.2-1.6-0.4c-0.6-0.6-0.5-1.5,0-2.1l-0.7-0.8
                c-0.8,0.8-1,2.2,0.1,3.4c0.9,1.1,2.2,1.3,2.9,0.6c0.7-0.6,0.8-1.5,0.2-2.5L74.4,15c-0.4-0.8-0.5-1.2-0.2-1.4
                c0.4-0.3,0.9-0.2,1.4,0.3c0.5,0.5,0.6,1.2,0.1,1.8l0.7,0.8c0.7-0.8,0.8-1.9-0.2-3.1c-0.8-0.9-2-1.2-2.8-0.5
                c-0.6,0.6-0.6,1.4-0.1,2.5L73.8,16.1z"/>
              <path d="M74.3,20.7l1.7-0.5l1.5,2.3l-1.1,1.4l0.5,0.9l4-5l-1-1.6l-6.2,1.6L74.3,20.7z M80.3,19.1L78,21.8L76.8,20
                L80.3,19.1z"/>
              <polygon points="78.3,27.8 84.1,26.7 79,29.8 79.7,31.6 85.9,30.5 85.5,29.6 79.6,30.6 84.7,27.5 84,25.7 78.1,26.9 
                83.1,23.6 82.8,22.7 77.6,26.1 	"/>
              <path d="M80.9,37.2l2.3-0.3l0.1,1.1c0.2,1.5,1,2.4,2.2,2.2c1.2-0.1,1.7-1.2,1.6-2.7l-0.2-2.1l-6.1,0.7L80.9,37.2z
                M86.1,36.6l0.1,1.2c0.1,0.9-0.2,1.4-0.9,1.5c-0.7,0.1-1.1-0.4-1.2-1.2L84,36.8L86.1,36.6z"/>
              <path d="M81.3,41.8l0,1l2.5,0.1l0,0.9c0,0,0,0.1,0,0.1l-2.5,1.2l0,1.1l2.7-1.3c0.2,0.8,0.8,1.3,1.6,1.3
                c1.2,0,1.8-0.9,1.9-2.4l0.1-1.9L81.3,41.8z M86.5,44c0,0.9-0.4,1.3-1,1.3c-0.6,0-1-0.5-0.9-1.3l0-1l2,0.1L86.5,44z"/>
              <path d="M84.1,47.6c-1.7-0.3-3.3,0.5-3.6,2.2c-0.3,1.7,0.8,3.1,2.5,3.4c1.7,0.3,3.3-0.5,3.6-2.2
                C87,49.4,85.8,48,84.1,47.6z M85.8,50.9c-0.2,1.1-1.3,1.6-2.6,1.4c-1.4-0.3-2.1-1.2-1.9-2.3c0.2-1.1,1.3-1.6,2.6-1.4
                C85.3,49,86,49.9,85.8,50.9z"/>
              <path d="M79,55.6c-0.7,1.8-0.1,3.4,1.7,4.1c1.8,0.7,3.3,0,4-1.9l0.7-1.7l-5.7-2.2L79,55.6z M80,55.1l4.2,1.6l-0.3,0.8
                c-0.5,1.3-1.5,1.7-2.8,1.2c-1.3-0.5-1.8-1.5-1.3-2.8L80,55.1z"/>
              <path d="M76,61.6c-0.7,1.3-0.5,2.4,1,3.3l3.2,1.9l0.5-0.9L77.4,64c-0.9-0.5-1.1-1.2-0.7-2c0.4-0.7,1.1-0.9,2-0.4
                l3.3,1.9l0.5-0.9l-3.2-1.9C77.9,60,76.7,60.4,76,61.6z"/>
              <path d="M76.5,66.7c-1.4-1.2-3.2-1.1-4.3,0.2c-0.9,1.1-0.9,2.5,0,3.6l0.7-0.8c-0.6-0.7-0.6-1.5-0.1-2.2
                c0.7-0.9,1.9-0.9,3,0c1.1,0.9,1.3,2,0.6,2.9c-0.6,0.7-1.4,0.8-2.2,0.3l-0.7,0.8c1.2,0.7,2.6,0.5,3.5-0.6
                C78.2,69.6,77.9,67.8,76.5,66.7z"/>
              <polygon points="72.2,74.6 68.8,70.6 68,71.3 71.5,75.2 70,76.5 70.5,77.1 74.3,73.9 73.7,73.3 	"/>
              <rect x="66.5" y="72.8" transform="matrix(0.8187 -0.5742 0.5742 0.8187 -31.432 52.2204)" width="1" height="6.1"/>
              <path d="M61.2,75.8c-1.5,0.8-1.9,2.6-1.1,4.1c0.8,1.5,2.5,2.2,4,1.4c1.5-0.8,1.9-2.6,1.1-4.1
                C64.4,75.6,62.7,75,61.2,75.8z M63.8,80.6c-1,0.5-2.1,0.1-2.7-1.2c-0.7-1.2-0.4-2.4,0.6-2.9c1-0.5,2.1,0,2.7,1.2
                C65,78.9,64.7,80.1,63.8,80.6z"/>
              <polygon points="56.6,77.9 58.4,83.6 54.5,78.6 52.6,79.2 54.5,85 55.4,84.7 53.7,79.1 57.5,84 59.4,83.4 57.5,77.6 	
                "/>
              
                <rect x="43" y="80.3" transform="matrix(9.298658e-03 -1 1 9.298658e-03 -40.3434 125.7601)" width="0.6" height="5.8"/>
              <polygon points="28.5,83.3 29.6,77.4 27.8,76.8 24.5,82 25.4,82.4 28.6,77.3 27.5,83.2 29.3,83.8 32.4,78.7 31.4,84.6 
                32.4,85 33.3,78.8 31.5,78.2 	"/>
              <path d="M25.6,75.6l-1.4,1.2l-2.3-1.4l0.4-1.8L21.5,73L20,79.3l1.6,1l4.9-4.1L25.6,75.6z M20.9,79.6l0.8-3.4l1.9,1.2
                L20.9,79.6z"/>
              <path d="M20,71.8l-1.6,1.9L17.7,73c0,0-0.1-0.1-0.1-0.1l0.6-2.7l-0.8-0.7l-0.7,2.9c-0.8-0.3-1.5-0.2-2.1,0.4
                c-0.8,0.9-0.5,2,0.7,3l1.5,1.3l4-4.6L20,71.8z M16.5,75.8l-0.8-0.7c-0.6-0.6-0.8-1.1-0.4-1.6c0.4-0.5,1-0.5,1.6,0.1l0.8,0.7
                L16.5,75.8z"/>
              <path d="M13,68.5c-0.4-0.7-0.4-1.2,0-1.5c0.4-0.4,1.1-0.1,1.6,0.4c0.6,0.7,0.5,1.5-0.1,2.1l0.7,0.8
                c0.8-0.8,1.1-2.2,0-3.4c-0.9-1.1-2.1-1.3-2.9-0.7c-0.7,0.6-0.8,1.5-0.3,2.5l0.4,0.8c0.4,0.8,0.5,1.2,0.2,1.5
                c-0.4,0.3-1,0.2-1.4-0.4c-0.5-0.5-0.6-1.2-0.1-1.8l-0.7-0.8c-0.7,0.8-0.9,1.9,0.2,3.1c0.8,0.9,2,1.2,2.8,0.6
                c0.6-0.6,0.7-1.4,0.1-2.5L13,68.5z"/>
              <path d="M12.7,63.9l-1.8,0.4l-1.4-2.3l1.2-1.4l-0.5-0.9L6,64.6l1,1.6l6.3-1.5L12.7,63.9z M6.6,65.3l2.3-2.7l1.2,1.9
                L6.6,65.3z"/>
              <polygon points="8.8,56.6 2.9,57.7 8.1,54.6 7.4,52.9 1.3,53.8 1.6,54.7 7.6,53.8 2.4,56.9 3.1,58.6 9,57.6 3.9,60.8 
                4.2,61.7 9.5,58.4 	"/>
              <path d="M6.4,47.2l-2.3,0.2L4,46.3C3.8,44.8,3,43.9,1.9,44c-1.2,0.1-1.8,1.1-1.6,2.6l0.2,2.1l6.1-0.6L6.4,47.2z
                M1.2,47.7L1,46.5C1,45.7,1.3,45.1,2,45.1c0.7-0.1,1.1,0.4,1.2,1.2l0.1,1.2L1.2,47.7z"/>
              <path d="M6.1,41.6l-2.5-0.1l0.1-0.9c0,0,0-0.1,0-0.1l2.5-1.2l0.1-1.1l-2.7,1.3C3.4,38.5,2.9,38,2,38
                c-1.2-0.1-1.9,0.8-1.9,2.3L0,42.3l6.1,0.3L6.1,41.6z M2.8,41.4l-2-0.1l0.1-1C1,39.4,1.3,39,2,39c0.6,0,1,0.5,0.9,1.4L2.8,41.4z"/>
              <path d="M3.4,36.7c1.7,0.4,3.3-0.5,3.7-2.1c0.4-1.7-0.7-3.1-2.4-3.5C3,30.7,1.4,31.5,1,33.2
                C0.6,34.9,1.7,36.3,3.4,36.7z M1.8,33.4c0.2-1.1,1.3-1.6,2.7-1.3c1.4,0.3,2.1,1.2,1.9,2.3C6.1,35.4,5,36,3.7,35.7
                C2.3,35.4,1.6,34.4,1.8,33.4z"/>
              <path d="M8.8,28.8c0.7-1.8,0.1-3.4-1.6-4.1c-1.7-0.7-3.3,0-4,1.8l-0.7,1.7l5.6,2.3L8.8,28.8z M7.7,29.3l-4.1-1.7
                l0.3-0.8c0.5-1.3,1.6-1.7,2.9-1.1C8,26.2,8.5,27.3,8,28.5L7.7,29.3z"/>
              <path d="M11.9,22.9c0.8-1.2,0.5-2.4-0.9-3.3l-3.1-1.9l-0.5,0.8l3.2,2c0.9,0.6,1.1,1.3,0.6,2c-0.4,0.7-1.2,0.9-2.1,0.3
                l-3.2-2l-0.5,0.8l3.1,1.9C9.9,24.5,11.1,24.1,11.9,22.9z"/>
              <path d="M15.7,17.8c0.9-1.1,1-2.5,0.1-3.6L15.1,15c0.6,0.7,0.6,1.6,0,2.2c-0.8,0.9-1.9,0.8-3-0.1
                c-1.1-0.9-1.3-2.1-0.5-2.9c0.6-0.7,1.4-0.8,2.2-0.3l0.7-0.8c-1.2-0.8-2.6-0.5-3.5,0.5c-1.1,1.3-0.8,3.1,0.5,4.2
                C12.8,19,14.6,19,15.7,17.8z"/>
              <polygon points="15.9,10 19.3,14.1 20.1,13.4 16.7,9.4 18.2,8.1 17.7,7.5 13.9,10.7 14.4,11.3 	"/>
              <rect x="20.7" y="5.8" transform="matrix(0.8317 -0.5552 0.5552 0.8317 -1.3408 13.2414)" width="1" height="6.1"/>
              <path d="M26.9,9.1c1.5-0.8,2-2.5,1.2-4.1c-0.8-1.6-2.5-2.3-4-1.5c-1.5,0.8-2,2.5-1.2,4.1C23.7,9.1,25.4,9.9,26.9,9.1z
                M24.5,4.2c1-0.5,2.1,0,2.7,1.2c0.6,1.2,0.4,2.4-0.6,2.9c-1,0.5-2.1,0-2.7-1.2C23.3,5.9,23.5,4.7,24.5,4.2z"/>
              <polygon points="31.6,7 30,1.4 33.7,6.4 35.6,5.9 33.9,0 32.9,0.3 34.6,6 30.9,0.9 29,1.5 30.7,7.3 	"/>
              <path d="M19,40.3c0.2-0.1,0.4-0.1,0.7-0.1c0.1,0,0.1-0.1,0-0.2c-0.2,0-0.4-0.3-0.6-0.7c-0.1-0.1-0.1-0.3-0.2-0.5
                c-0.1-0.2-0.3-0.6-0.5-1c-0.3-0.7-0.5-1.4-0.8-2c-0.1-0.1-0.1-0.1-0.2,0c-0.7,1.8-1.1,2.8-1.4,3.4c-0.2,0.4-0.3,0.7-0.6,0.8
                c-0.1,0.1-0.1,0.1,0.1,0.2c0.4-0.1,0.8-0.1,1.1,0c0.1,0,0.1-0.1,0-0.2c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.2,0.1-0.4,0.2-0.7
                c0,0,0.1,0,0.3,0h0.9h0.7c0.1,0.3,0.2,0.6,0.4,1.1l0.1,0.3C18.9,40.3,18.9,40.3,19,40.3z M16.5,38.6c0.1-0.3,0.3-0.9,0.5-1.3
                c0.1-0.3,0.2-0.4,0.3-0.6c0.2,0.3,0.4,0.7,0.7,1.5l0.2,0.5H16.5z"/>
              <path d="M24.7,37.4c0.1-0.3,0.1-0.4,0.2-0.7c0-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.3,0.3-0.3c0.1-0.1,0.1-0.1,0-0.2
                c-0.5,0.1-0.8,0.1-1.1,0.1c-0.1,0-0.1,0.1,0,0.2c0.3,0,0.4,0.3,0.4,0.6c0,0.2,0,0.4-0.1,0.7L24,39.2c-0.3-0.4-0.4-0.8-0.6-1.3
                c0-0.1-0.1-0.3-0.2-0.5L23,36.8c-0.1-0.3-0.2-0.6-0.2-0.8c-0.1-0.1-0.1-0.1-0.2,0c-0.1,0.4-0.2,0.7-0.3,1l-0.2,0.7l-0.3,0.8
                c-0.1,0.2-0.2,0.5-0.3,0.8c-0.2-0.4-0.4-0.8-0.6-1.5c-0.2-0.7-0.4-1.4-0.5-1.8c0-0.1-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0.1-0.7,0.1
                c-0.1,0-0.1,0.1,0,0.2c0.3,0,0.5,0.5,1,1.9l0.4,1.2c0.1,0.3,0.2,0.6,0.3,1c0.1,0.1,0.1,0.1,0.2,0c0.1-0.3,0.2-0.5,0.3-0.9
                c0.2-0.5,0.3-0.9,0.5-1.3l0.3-1c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.1,0.1,0.3,0.2,0.5c0.3,0.8,0.5,1.4,0.6,1.8c0.1,0.1,0.1,0.1,0.2,0
                c0.1-0.4,0.1-0.6,0.2-0.9L24.7,37.4z"/>
              <path d="M28.1,38.9c-0.1-0.2-0.3-0.6-0.5-1c-0.3-0.7-0.5-1.4-0.8-2c-0.1-0.1-0.1-0.1-0.2,0c-0.7,1.8-1.1,2.8-1.4,3.4
                c-0.2,0.4-0.3,0.7-0.6,0.8c-0.1,0.1-0.1,0.1,0.1,0.2c0.4-0.1,0.8-0.1,1.1,0c0.1,0,0.1-0.1,0-0.2c-0.3-0.1-0.4-0.3-0.4-0.6
                c0-0.2,0.1-0.4,0.2-0.7c0,0,0.1,0,0.3,0h0.9h0.7c0.1,0.3,0.2,0.6,0.4,1.1l0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.2-0.1,0.4-0.1,0.7-0.1
                c0.1,0,0.1-0.1,0-0.2c-0.2,0-0.4-0.3-0.6-0.7C28.3,39.3,28.2,39.1,28.1,38.9z M25.8,38.6c0.1-0.3,0.3-0.9,0.5-1.3
                c0.1-0.3,0.2-0.4,0.3-0.6c0.2,0.3,0.4,0.7,0.7,1.5l0.2,0.5H25.8z"/>
              <path d="M32.3,40.2c0.1,0,0.2,0,0.5,0c0.1,0,0.1-0.1,0-0.2c-0.3,0-0.5-0.3-0.8-0.8c-0.2-0.3-0.3-0.6-0.5-0.9
                c0-0.1-0.1-0.1-0.1-0.2c0.6-0.2,1-0.7,1-1.3c0-0.6-0.5-1-1.2-1c-0.4,0-0.6,0.1-1,0.1c-0.3,0-0.5,0-0.7,0c-0.1,0-0.1,0.1,0,0.1
                c0.1,0,0.2,0.1,0.2,0.2c0,0.2,0,0.3,0.1,0.4l0,0.4c0,0.5,0,0.8,0,1.1c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.2,0.3
                c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4-0.1,0.6,0c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3,0-0.5
                c0-0.4,0-0.6,0-1v0c0,0,0.1,0,0.2,0c0.4,0,0.6,0.1,0.8,0.4c0.5,0.8,0.6,1.2,0.9,1.5c0.1,0.1,0.1,0.1,0.2,0.1
                C32.1,40.2,32.2,40.2,32.3,40.2z M30.9,38c-0.1,0-0.4,0-0.8,0v-1c0-0.2,0-0.4,0-0.5l0-0.3c0-0.2,0.1-0.3,0.5-0.3
                c0.7,0,1.2,0.5,1.2,1.1C31.7,37.6,31.5,38,30.9,38z"/>
              <path d="M34.5,35.9c-0.3,0-0.5,0-0.8,0c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2c0,0.2,0.1,0.3,0.1,0.4
                c0,0.1,0,0.2,0,0.4c0,0.5,0,0.8,0,1.2c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.2,0,0.2
                c0.1,0,0.3-0.1,0.5-0.1c0.4,0,0.7,0.1,1.1,0.1c0.6,0,1-0.2,1.2-0.4c0.2-0.2,0.5-0.4,0.6-0.6c0.3-0.4,0.4-0.8,0.4-1.4
                c0-0.5-0.1-0.9-0.3-1.2c-0.1-0.2-0.4-0.5-0.9-0.7c-0.3-0.1-0.5-0.2-0.8-0.2C35.2,35.8,34.9,35.8,34.5,35.9z M36.6,36.7
                c0.2,0.2,0.3,0.4,0.5,0.9c0.1,0.2,0.1,0.5,0.1,0.8c0,0.4-0.1,0.7-0.3,1c-0.1,0.3-0.6,0.7-1.3,0.7c-0.3,0-0.7-0.1-1-0.3
                c-0.2-0.1-0.2-0.4-0.2-0.7c0-0.3,0-0.5,0-0.8c0-0.7,0-1.1,0-1.5c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.5-0.1
                C35.7,36,36.2,36.2,36.6,36.7z"/>
              <path d="M39.4,38.5c0.2,0,0.8,0,1.5,0c0.8,0,1.3,0,1.5,0c0.3,0,0.4,0,0.4,0c0.1-0.1,0.1-0.1,0-0.2c0,0-0.1,0-0.4,0H41
                h-1.5c-0.2,0-0.3,0-0.3,0c-0.1,0.1-0.1,0.1,0,0.2C39.1,38.5,39.2,38.5,39.4,38.5z"/>
              <path d="M46.8,35.9c-0.1-0.1-0.1-0.1-0.2,0c-0.1,0.4-0.2,0.7-0.3,1l-0.2,0.7l-0.3,0.8c-0.1,0.2-0.2,0.5-0.3,0.8
                c-0.2-0.4-0.4-0.8-0.6-1.5c-0.2-0.7-0.4-1.4-0.5-1.8c0-0.1-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.3,0,0.5,0.5,1,1.9l0.4,1.2c0.1,0.3,0.2,0.6,0.3,1c0.1,0.1,0.1,0.1,0.2,0c0.1-0.3,0.2-0.5,0.3-0.9c0.2-0.5,0.3-0.9,0.5-1.3l0.3-1
                c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.1,0.1,0.3,0.2,0.5c0.3,0.8,0.5,1.4,0.6,1.8c0.1,0.1,0.1,0.1,0.2,0c0.1-0.4,0.1-0.6,0.2-0.9l0.6-1.9
                c0.1-0.3,0.1-0.4,0.2-0.7c0-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.3,0.3-0.3c0.1-0.1,0.1-0.1,0-0.2c-0.5,0.1-0.8,0.1-1.1,0.1
                c-0.1,0-0.1,0.1,0,0.2c0.3,0,0.4,0.3,0.4,0.6c0,0.2,0,0.4-0.1,0.7L48,39.2c-0.3-0.4-0.4-0.8-0.6-1.3c0-0.1-0.1-0.3-0.2-0.5L47,36.8
                C46.9,36.4,46.8,36.2,46.8,35.9z"/>
              <path d="M50.1,36.2c0,0.1,0.1,0.3,0.1,0.4l0,0.4c0,0.5,0,0.8,0,1.2c0,0.4,0,0.8,0,1.1c0,0.2,0,0.4-0.1,0.5
                c0,0.2-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4-0.1,0.6,0c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3
                c0-0.2,0-0.3-0.1-0.5c0-0.4,0-0.7,0-1.1v-1c0-0.1,0-0.3,0-0.4l0-0.4c0-0.3,0-0.5,0-0.6c0-0.1,0-0.1-0.1-0.1
                c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.1C50,36,50,36.1,50.1,36.2z"/>
              <path d="M52.2,36.7c0,0.3,0,1.4,0,2c0,0.7,0,1.2-0.4,1.4c-0.1,0.1-0.1,0.1,0,0.2c0.4-0.1,0.8,0,1.3,0
                c0.1,0,0.1-0.1,0-0.2c-0.7-0.1-0.8-0.9-0.8-1.4c0-0.5,0-1.7,0-2.2c0.3,0.3,0.8,0.8,1.5,1.7c0.7,0.9,1.1,1.4,1.6,2
                c0.1,0.1,0.1,0.1,0.2-0.1c0-0.9,0-1.3,0-2.8c0-0.7,0-1.2,0.4-1.4c0.1-0.1,0.1-0.2-0.1-0.2c-0.4,0.1-0.6,0.1-1.2,0.1
                c-0.1,0-0.1,0.1,0,0.2c0.7,0,0.7,0.8,0.7,1.3l0,1.8c-0.4-0.3-0.7-0.7-1.2-1.3c-0.7-0.9-1.3-1.5-1.6-2c-0.1-0.1-0.2-0.1-0.3-0.1
                c-0.3,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.2C52.1,36.1,52.2,36.3,52.2,36.7z"/>
              <path d="M60.5,35.9c0.1-0.1,0.1-0.2-0.1-0.2c-0.4,0.1-0.6,0.1-1.2,0.1c-0.1,0-0.1,0.1,0,0.2c0.7,0,0.7,0.8,0.7,1.3
                l0,1.8c-0.4-0.3-0.7-0.7-1.2-1.3c-0.7-0.9-1.3-1.5-1.6-2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.4,0.1,0.5,0.3,0.6,0.6c0,0.3,0,1.4,0,2c0,0.7,0,1.2-0.4,1.4c-0.1,0.1-0.1,0.1,0,0.2c0.4-0.1,0.8,0,1.3,0c0.1,0,0.1-0.1,0-0.2
                C57,39.9,57,39.2,57,38.7c0-0.5,0-1.7,0-2.2c0.3,0.3,0.8,0.8,1.5,1.7c0.7,0.9,1.1,1.4,1.6,2c0.1,0.1,0.1,0.1,0.2-0.1
                c0-0.9,0-1.3,0-2.8C60.2,36.7,60.2,36.1,60.5,35.9z"/>
              <path d="M62.6,40.1c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3-0.1-0.5c0-0.4,0-0.7,0-1.1v-1c0-0.1,0-0.3,0-0.4l0-0.4
                c0-0.3,0-0.5,0-0.6c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2c0,0.1,0.1,0.3,0.1,0.4
                l0,0.4c0,0.5,0,0.8,0,1.2c0,0.4,0,0.8,0,1.1c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1,0,0.2
                c0.1,0,0.4-0.1,0.6,0c0.1,0,0.2,0,0.4,0C62.7,40.2,62.7,40.2,62.6,40.1z"/>
              <path d="M67.3,35.9c0.1-0.1,0.1-0.2-0.1-0.2c-0.4,0.1-0.6,0.1-1.2,0.1c-0.1,0-0.1,0.1,0,0.2c0.7,0,0.7,0.8,0.7,1.3
                l0,1.8c-0.4-0.3-0.7-0.7-1.2-1.3c-0.7-0.9-1.3-1.5-1.6-2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.4,0.1,0.5,0.3,0.6,0.6c0,0.3,0,1.4,0,2c0,0.7,0,1.2-0.4,1.4c-0.1,0.1-0.1,0.1,0,0.2c0.4-0.1,0.8,0,1.3,0c0.1,0,0.1-0.1,0-0.2
                c-0.7-0.1-0.8-0.9-0.8-1.4c0-0.5,0-1.7,0-2.2c0.3,0.3,0.8,0.8,1.5,1.7c0.7,0.9,1.1,1.4,1.6,2c0.1,0.1,0.1,0.1,0.2-0.1
                c0-0.9,0-1.3,0-2.8C66.9,36.7,66.9,36.1,67.3,35.9z"/>
              <path d="M69.8,36c0.6,0,1.1,0.4,1.3,1.1c0.1,0.1,0.1,0.1,0.1,0c0-0.3,0-0.6,0.1-0.9c0-0.1,0-0.1,0-0.1
                c-0.2-0.2-0.6-0.3-0.8-0.3c-0.1,0-0.3,0-0.4,0c-0.6,0-1,0.2-1.2,0.4c-0.2,0.1-0.4,0.4-0.6,0.6c-0.2,0.4-0.4,0.8-0.4,1.4
                c0,1.2,0.7,2.2,1.7,2.2c0.3,0,0.7-0.1,1.1-0.5c0.1-0.2,0.3-0.2,0.4,0c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.1,0.1,0.2,0
                c0-0.2,0-0.5,0-0.9c0-0.2,0-0.3,0-0.3c0-0.5,0-0.6,0.2-0.7c0.1-0.1,0.1-0.2-0.1-0.2c-0.4,0.1-0.9,0.1-1.4,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.3,0,0.4,0,0.6,0.1C71,38.6,71,38.7,71,39c0,0.6-0.6,1-1,1c-0.9,0-1.6-1.1-1.6-2.4c0-0.3,0-0.7,0.4-1.1C69,36.2,69.4,36,69.8,36z
                "/>
              <path d="M16.6,43.6c0-0.3,0-0.4,0.3-0.4c0.3,0,0.6,0,0.7,0c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.4,0.3,0.5,0.8
                c0.1,0.1,0.1,0.1,0.2,0c0-0.4,0.1-0.7,0.2-1c0-0.1,0-0.2-0.1-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.3,0-0.5,0c-0.3,0-0.9,0-2,0
                c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0,0.4c0,0.5,0,0.9,0,1.2c0,0.4,0,0.7,0,1.1
                c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.2,0,0.2c0.1,0,0.4-0.1,0.6,0c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0-0.2
                c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3,0-0.5c0-0.4,0-0.7,0-1.1v0c1,0,1.3,0,1.4,0.6c0.1,0.1,0.1,0.1,0.2,0c0-0.4,0-0.6,0.1-1.2
                c0-0.1-0.1-0.1-0.2-0.1c-0.2,0.3-0.4,0.4-0.9,0.4c-0.2,0-0.4,0-0.7,0c0-0.4,0-0.7,0-1.2C16.6,43.8,16.6,43.7,16.6,43.6z"/>
              <path d="M19.4,47c0,0.2-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4-0.1,0.6,0c0.1,0,0.2,0,0.4,0
                c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3-0.1-0.5c0-0.4,0-0.7,0-1.1v-1c0-0.1,0-0.3,0-0.4l0-0.4c0-0.3,0-0.5,0-0.6
                c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2c0,0.1,0.1,0.3,0.1,0.4l0,0.4
                c0,0.5,0,0.8,0,1.2c0,0.4,0,0.8,0,1.1C19.5,46.7,19.5,46.9,19.4,47z"/>
              <path d="M21.4,47c0,0.2-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.1,0,0.2c0.2,0,0.5,0,0.8,0c0.5,0,0.9,0,1.1,0
                c0.3,0,0.4,0,0.6,0.1c0.1,0,0.1,0,0.1-0.1c0-0.4,0.1-0.6,0.2-1c0-0.2,0-0.2-0.2-0.1c-0.2,0.3-0.3,0.6-0.7,0.8
                c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.3,0-0.3-0.2c0-0.2,0-0.3,0-0.5c0-0.4,0-0.7,0-1.2v-1c0-0.1,0-0.3,0-0.4l0-0.4
                c0-0.3,0-0.5,0-0.6c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2
                c0,0.1,0.1,0.3,0.1,0.4l0,0.4c0,0.5,0,0.8,0,1.2c0,0.4,0,0.7,0,1.1C21.4,46.7,21.4,46.9,21.4,47z"/>
              <path d="M28.5,47.3c-0.1,0.1-0.1,0.1,0,0.2c0.3-0.1,0.6-0.1,1,0c0.1,0,0.2-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3
                c0-0.2-0.1-0.4-0.1-0.5c0-0.4-0.1-0.7-0.1-1.1c0-0.3-0.1-0.7-0.1-1.1L29,43.3c0-0.2,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.1
                c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3-0.1,0.4l-0.3,0.7l-0.4,1l-0.4,0.9
                c-0.2-0.2-0.5-0.8-0.9-1.6c-0.4-0.8-0.8-1.5-0.8-1.8c0-0.1-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0.1-0.8,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.4,0,0.5,0.2,0.5,0.6c0,0.2-0.1,1.4-0.2,2c0,0.5-0.1,0.8-0.2,1.1c0,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.1,0.1,0,0.2
                c0.6-0.1,0.9,0,1.3,0c0.1,0,0.1-0.1,0-0.2c-0.9-0.1-0.7-0.9-0.7-1.4c0-0.4,0.1-0.8,0.1-1.1l0.1-0.7c0-0.1,0-0.3,0-0.4
                c0.3,0.5,0.7,1.3,1.4,2.6c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.1,0.1,0.1,0.2,0c0.1-0.4,0.3-0.7,0.4-1.1c0.2-0.4,0.3-0.7,0.5-1.1
                c0.1-0.3,0.3-0.6,0.5-1.2c0.1,0.3,0.1,0.7,0.1,1c0,0.2,0,0.4,0.1,0.6c0,0.4,0.1,0.7,0.1,1.1c0,0.2,0,0.4,0,0.5S28.7,47.2,28.5,47.3
                z"/>
              <path d="M35.2,44.8c0.1-0.3,0.2-0.5,0.2-0.7c0-0.7-0.5-1.2-1.2-1.2c-0.4,0-0.6,0.1-1,0.1c-0.2,0-0.4,0-0.7,0
                c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.2,0,0.3,0.1,0.4l0,0.4c0,0.5,0,0.8,0,1.1c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4-0.1,0.5
                c0,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4,0,0.6,0c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.1-0.1-0.2-0.2
                c0-0.1-0.1-0.3-0.1-0.5c0-0.3,0-0.6,0-1c0.1,0,0.3,0,0.5,0C34.4,45.6,35,45.2,35.2,44.8z M33.3,45.4v-0.2c0-1.3,0-1.4,0-1.8
                c0-0.2,0.1-0.3,0.4-0.3c0.7,0,1.1,0.5,1.1,1.2c0,0.6-0.3,1-0.8,1.1C33.8,45.4,33.6,45.4,33.3,45.4z"/>
              <path d="M37,47.3c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3,0-0.5c0-0.4,0-0.6,0-1v0c0,0,0.1,0,0.2,0c0.4,0,0.6,0.1,0.8,0.4
                c0.5,0.8,0.6,1.2,0.9,1.5c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.5,0c0.1,0,0.1-0.1,0-0.2
                c-0.3,0-0.5-0.3-0.8-0.8c-0.2-0.3-0.3-0.6-0.5-0.9c0-0.1-0.1-0.1-0.1-0.2c0.6-0.2,1-0.7,1-1.3c0-0.6-0.5-1-1.2-1
                c-0.4,0-0.6,0.1-1,0.1c-0.3,0-0.5,0-0.7,0c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.2,0,0.3,0.1,0.4l0,0.4
                c0,0.5,0,0.8,0,1.1c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4-0.1,0.6,0
                c0.1,0,0.2,0,0.4,0C37.1,47.4,37.1,47.3,37,47.3z M36.7,44.3c0-0.2,0-0.4,0-0.5l0-0.3c0-0.2,0.1-0.3,0.5-0.3c0.7,0,1.2,0.5,1.2,1.1
                c0,0.5-0.3,0.9-0.8,1c-0.1,0-0.4,0-0.8,0V44.3z"/>
              <path d="M43.6,46.5c0.2-0.4,0.4-0.8,0.4-1.3c0-1.3-0.9-2.3-2-2.3c-0.6,0-1.1,0.3-1.4,0.6c-0.1,0.2-0.3,0.3-0.3,0.4
                c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,0.9,0.3,1.3c0.1,0.2,0.4,0.6,0.9,0.8c0.2,0.1,0.5,0.2,0.8,0.2c0.6,0,1.1-0.3,1.4-0.6
                C43.4,46.8,43.5,46.6,43.6,46.5z M42.1,47.3c-1.2-0.1-1.8-1.4-1.8-2.5c0-0.8,0.5-1.6,1.3-1.6c0.9,0,1.9,1.3,1.9,2.4
                c0,0.3,0,0.7-0.3,1.2C42.9,47.1,42.6,47.3,42.1,47.3z"/>
              <path d="M47.6,47.1c0.2-0.2,0.5-0.4,0.6-0.6c0.3-0.4,0.4-0.8,0.4-1.4c0-0.5-0.1-0.9-0.3-1.2c-0.1-0.2-0.4-0.5-0.9-0.7
                C47.2,43,46.9,43,46.6,43c-0.4,0-0.7,0.1-1.1,0.1c-0.3,0-0.5,0-0.8,0c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2
                c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.4c0,0.5,0,0.8,0,1.2c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.3
                c-0.1,0.1-0.1,0.2,0,0.2c0.1,0,0.3-0.1,0.5-0.1c0.4,0,0.7,0.1,1.1,0.1C47,47.5,47.4,47.2,47.6,47.1z M45.5,46.2c0-0.3,0-0.5,0-0.8
                c0-0.7,0-1.1,0-1.5c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.5-0.1c0.6,0,1.2,0.3,1.5,0.7c0.2,0.2,0.3,0.4,0.5,0.9
                c0.1,0.2,0.1,0.5,0.1,0.8c0,0.4-0.1,0.7-0.3,1c-0.1,0.3-0.6,0.7-1.3,0.7c-0.3,0-0.7-0.1-1-0.3C45.5,46.7,45.5,46.4,45.5,46.2z"/>
              <path d="M50.2,45.8c0-0.2,0-0.4,0-0.6v-1l0-0.5c0-0.3,0-0.5,0-0.6c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.4,0.1-0.7,0.1
                c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.3c0,0.2,0.1,0.4,0.1,0.5c0,0.4,0,0.6,0,1.1c0,0.1,0,0.2,0,0.4v0.4c0,0.3,0,0.7,0.4,1.1
                c0.3,0.3,0.6,0.4,1,0.4c0.7,0,1.2-0.4,1.3-0.7c0.2-0.3,0.3-0.6,0.3-1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-1c0-0.8,0-1.3,0.3-1.4
                c0.1-0.1,0.1-0.2-0.1-0.2c-0.2,0.1-0.5,0.1-1,0.1c-0.1,0-0.1,0.1,0,0.2c0.6,0.1,0.6,0.6,0.6,1.3c0,0.4,0,0.7,0,0.9
                c0,0.2,0,0.3,0,0.3c0,0.8-0.5,1.3-1.2,1.3C50.7,47.2,50.3,46.7,50.2,45.8z"/>
              <path d="M55,43.2c0.6,0,1.1,0.3,1.3,1.1c0.1,0.1,0.1,0.1,0.1,0c0-0.3,0-0.5,0.1-0.8c0-0.1,0-0.1-0.1-0.2
                C56.4,43.1,56,43,55.8,43c-0.1,0-0.2,0-0.4,0c-0.5,0-0.9,0.2-1.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.4-0.4,0.8-0.4,1.4
                c0,1.2,0.7,2.2,1.9,2.2c0.8,0,1.3-0.5,1.6-1c0-0.2,0-0.2-0.1-0.1c-0.2,0.3-0.5,0.7-1.1,0.7c-1,0-1.8-1.2-1.8-2.3
                c0-0.3,0-0.7,0.4-1.1C54.4,43.4,54.7,43.2,55,43.2z"/>
              <path d="M59.2,47c0-0.2,0-0.3,0-0.5c0-0.4,0-0.7,0-1.1v-0.9l0-0.6c0-0.2,0-0.4,0-0.5c0-0.2,0.1-0.3,0.2-0.2
                c0.5,0,0.8,0.2,0.9,0.8c0.1,0.1,0.1,0.1,0.2,0c0-0.3,0-0.7,0.2-1c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.8,0.1-1.6,0.1c-0.4,0-0.7,0-1,0
                c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.2,0,0.4,0,0.6c0,0.1,0,0.3-0.1,0.5c0,0.2,0.1,0.2,0.2,0c0.2-0.5,0.3-0.7,0.6-0.8
                c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.2,0.1,0.2,0.2c0,0.3,0,0.8,0,1.9c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.3
                c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0-0.2C59.3,47.2,59.2,47.2,59.2,47z"/>
              <path d="M61.5,47c0,0.2-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4-0.1,0.6,0c0.1,0,0.2,0,0.4,0
                c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3-0.1-0.5c0-0.4,0-0.7,0-1.1v-1c0-0.1,0-0.3,0-0.4l0-0.4c0-0.3,0-0.5,0-0.6
                c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0,0.2,0.2c0,0.1,0.1,0.3,0.1,0.4l0,0.4
                c0,0.5,0,0.8,0,1.2c0,0.4,0,0.8,0,1.1C61.6,46.7,61.5,46.9,61.5,47z"/>
              <path d="M65.1,43c-0.6,0-1.1,0.3-1.4,0.6c-0.1,0.2-0.3,0.3-0.3,0.4c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,0.9,0.3,1.3
                c0.1,0.2,0.4,0.6,0.9,0.8c0.2,0.1,0.5,0.2,0.8,0.2c0.6,0,1.1-0.3,1.4-0.6c0.1-0.2,0.3-0.3,0.3-0.4c0.2-0.4,0.4-0.8,0.4-1.3
                C67.1,44,66.2,43,65.1,43z M66.3,46.8c-0.2,0.3-0.6,0.5-1,0.5c-1.2-0.1-1.8-1.4-1.8-2.5c0-0.8,0.5-1.6,1.3-1.6
                c0.9,0,1.9,1.3,1.9,2.4C66.6,45.9,66.6,46.3,66.3,46.8z"/>
              <path d="M72,43.1c0.1-0.1,0.1-0.2-0.1-0.2c-0.4,0.1-0.6,0.1-1.2,0.1c-0.1,0-0.1,0.1,0,0.2c0.7,0,0.7,0.8,0.7,1.3l0,1.8
                c-0.4-0.3-0.7-0.7-1.2-1.3c-0.7-0.9-1.3-1.5-1.6-2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.4,0.1,0.5,0.3,0.6,0.6c0,0.3,0,1.4,0,2c0,0.7,0,1.2-0.4,1.4c-0.1,0.1-0.1,0.1,0,0.2c0.4-0.1,0.8,0,1.3,0c0.1,0,0.1-0.1,0-0.2
                c-0.7-0.1-0.8-0.9-0.8-1.4c0-0.5,0-1.7,0-2.2c0.3,0.3,0.8,0.8,1.5,1.7c0.7,0.9,1.1,1.4,1.6,2c0.1,0.1,0.1,0.1,0.2-0.1
                c0-0.9,0-1.3,0-2.8C71.6,43.9,71.6,43.3,72,43.1z"/>
              <path d="M30.4,54.7c0.8,0,1.3-0.5,1.6-1c0-0.2,0-0.2-0.1-0.1c-0.2,0.3-0.5,0.7-1.1,0.7c-1,0-1.8-1.2-1.8-2.3
                c0-0.3,0-0.7,0.4-1.1c0.2-0.3,0.6-0.4,0.9-0.4c0.6,0,1.1,0.3,1.3,1.1c0.1,0.1,0.1,0.1,0.1,0c0-0.3,0-0.5,0.1-0.8
                c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.2,0-0.4,0c-0.5,0-0.9,0.2-1.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.6
                c-0.2,0.4-0.4,0.8-0.4,1.4C28.5,53.7,29.3,54.7,30.4,54.7z"/>
              <path d="M34.6,50.2c-0.6,0-1.1,0.3-1.4,0.6c-0.1,0.2-0.3,0.3-0.3,0.4c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,0.9,0.3,1.3
                c0.1,0.2,0.4,0.6,0.9,0.8c0.2,0.1,0.5,0.2,0.8,0.2c0.6,0,1.1-0.3,1.4-0.6c0.1-0.2,0.3-0.3,0.3-0.4c0.2-0.4,0.4-0.8,0.4-1.3
                C36.6,51.2,35.8,50.2,34.6,50.2z M35.8,53.9c-0.2,0.3-0.6,0.5-1,0.5C33.6,54.4,33,53,33,52c0-0.8,0.5-1.6,1.3-1.6
                c0.9,0,1.9,1.3,1.9,2.4C36.1,53.1,36.1,53.4,35.8,53.9z"/>
              <path d="M41.3,51c0.1,0.3,0.1,0.7,0.1,1c0,0.2,0,0.4,0.1,0.6c0,0.4,0.1,0.7,0.1,1.1c0,0.2,0,0.4,0,0.5
                c0,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.1,0,0.2c0.3-0.1,0.6-0.1,1,0c0.1,0,0.2-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3
                c0-0.2-0.1-0.4-0.1-0.5c0-0.4-0.1-0.7-0.1-1.1c0-0.3-0.1-0.7-0.1-1.1l-0.1-0.9c0-0.2,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.1
                c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3-0.1,0.4l-0.3,0.7l-0.4,1L40,53.6
                c-0.2-0.2-0.5-0.8-0.9-1.6c-0.4-0.8-0.8-1.5-0.8-1.8c0-0.1-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0.1-0.8,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.4,0,0.5,0.2,0.5,0.6c0,0.2-0.1,1.4-0.2,2c0,0.5-0.1,0.8-0.2,1.1c0,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.1,0.1,0,0.2
                c0.6-0.1,0.9,0,1.3,0c0.1,0,0.1-0.1,0-0.2c-0.9-0.1-0.7-0.9-0.7-1.4c0-0.4,0.1-0.8,0.1-1.1l0.1-0.7c0-0.1,0-0.3,0-0.4
                c0.3,0.4,0.7,1.3,1.4,2.6c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.1,0.1,0.1,0.2,0c0.1-0.4,0.3-0.7,0.4-1.1c0.2-0.4,0.3-0.7,0.5-1.1
                C40.9,51.8,41.1,51.5,41.3,51z"/>
              <path d="M45.7,52c0.1-0.3,0.2-0.5,0.2-0.7c0-0.7-0.5-1.2-1.2-1.2c-0.4,0-0.6,0.1-1,0.1c-0.2,0-0.4,0-0.7,0
                c-0.1,0-0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.2,0,0.3,0.1,0.4l0,0.4c0,0.5,0,0.8,0,1.1c0,0.4,0,0.7,0,1.1c0,0.2,0,0.4-0.1,0.5
                c0,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4,0,0.6,0c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.1-0.1-0.2-0.2
                c0-0.1-0.1-0.3-0.1-0.5c0-0.3,0-0.6,0-1c0.1,0,0.3,0,0.5,0C44.9,52.8,45.5,52.4,45.7,52z M43.8,52.6v-0.2c0-1.3,0-1.4,0-1.8
                c0-0.2,0.1-0.3,0.4-0.3c0.7,0,1.1,0.5,1.1,1.2c0,0.6-0.3,1-0.8,1.1C44.3,52.6,44.1,52.6,43.8,52.6z"/>
              <path d="M49.5,53.8c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.2-0.3-0.6-0.5-1c-0.3-0.7-0.5-1.4-0.8-2c-0.1-0.1-0.1-0.1-0.2,0
                c-0.7,1.8-1.1,2.8-1.4,3.4c-0.2,0.4-0.3,0.7-0.6,0.8c-0.1,0.1-0.1,0.1,0.1,0.2c0.4-0.1,0.8-0.1,1.1,0c0.1,0,0.1-0.1,0-0.2
                c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.2,0.1-0.4,0.2-0.7c0,0,0.1,0,0.3,0H48h0.7c0.1,0.3,0.2,0.6,0.4,1.1l0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1
                c0.2-0.1,0.4-0.1,0.7-0.1c0.1,0,0.1-0.1,0-0.2C49.8,54.4,49.7,54.1,49.5,53.8z M46.9,53c0.1-0.3,0.3-0.9,0.5-1.3
                c0.1-0.3,0.2-0.4,0.3-0.6c0.2,0.3,0.4,0.7,0.7,1.5l0.2,0.5H46.9z"/>
              <path d="M53.9,54.6c0.1,0.1,0.1,0.1,0.2-0.1c0-0.9,0-1.3,0-2.8c0-0.7,0-1.2,0.4-1.4c0.1-0.1,0.1-0.2-0.1-0.2
                c-0.4,0.1-0.6,0.1-1.2,0.1c-0.1,0-0.1,0.1,0,0.2c0.7,0,0.7,0.8,0.7,1.3l0,1.8c-0.4-0.3-0.7-0.7-1.2-1.3c-0.7-0.9-1.3-1.5-1.6-2
                c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.1,0.1,0,0.2c0.4,0.1,0.5,0.3,0.6,0.6c0,0.3,0,1.4,0,2
                c0,0.7,0,1.2-0.4,1.4c-0.1,0.1-0.1,0.1,0,0.2c0.4-0.1,0.8,0,1.3,0c0.1,0,0.1-0.1,0-0.2c-0.7-0.1-0.8-0.9-0.8-1.4c0-0.5,0-1.7,0-2.2
                c0.3,0.3,0.8,0.8,1.5,1.7C53,53.5,53.4,54,53.9,54.6z"/>
              <path d="M57.4,52.4c-0.7-1.2-1-1.6-1.2-2.1c0-0.1-0.1-0.1-0.2-0.1c-0.3,0.1-0.5,0.1-0.8,0.1c-0.1,0-0.1,0.1,0,0.2
                c0.3,0,0.6,0.4,0.8,0.7c0.2,0.3,0.5,0.8,0.9,1.5c0,0.4,0,0.7,0,1.1c0,0.4,0,0.7-0.3,0.7c-0.1,0.1-0.1,0.1,0,0.2c0.1,0,0.4,0,0.6,0
                l0.4,0c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.4,0-0.6v-1c0.3-0.4,0.5-0.7,0.7-1c0.3-0.5,0.5-0.7,0.7-0.9
                c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.1-0.2-0.1-0.2c-0.4,0.2-0.7,0.1-1.1,0.1c-0.1,0-0.1,0.1,0,0.2c0.2,0,0.3,0.2,0.3,0.4
                c0,0.3-0.1,0.4-0.3,0.6C57.9,51.7,57.6,52.1,57.4,52.4z"/>
            </g>
          </svg>
        </div>
      </div>
    )
  }
}

export default Intro