import React, { Component } from 'react'

import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import logo from "../assets/images/logo.svg"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuToggled: false,
    }
  }

  handleClick = () => {
    this.setState({
      menuToggled: !this.state.menuToggled,
    })
  }

  render() {
    const navClasses = this.state.menuToggled ? 'main-nav toggled' : 'main-nav'
    const mobileMenuBtnClasses = this.state.menuToggled
      ? 'mobile-menu-btn open'
      : 'mobile-menu-btn'

    return (
      <header className="masthead">
        <div className="upper-header">
          <Link to="/" className="logo" title="Home">
            <img src={logo} alt="Logo Warsaw Production" />
          </Link>
          <button className={mobileMenuBtnClasses} onClick={this.handleClick}>
            <div className="icon"></div>
          </button>
          <a href="https://www.instagram.com/warsawproduction/" className="upper-header--instagram" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="#999"><path d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z"/></svg>
          </a>
        </div>
        <nav className={navClasses}>
          <ul>
            {this.props.menu &&
              this.props.menu.items.map((menuItem, i) => {
                return (
                  <li key={i}>
                    {menuItem.url.startsWith('http') ? (
                      <a
                        href={menuItem.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {menuItem.title}
                      </a>
                    ) : (
                      <Link to={menuItem.url} activeClassName="active">
                        {menuItem.title}
                      </Link>
                    )}
                  </li>
                )
              })}
          </ul>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
